.Statement {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: 'Pangolin', sans-serif;
  font-size: 1.4rem;
  margin: 0.8rem 0;
}

.Statement-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: auto;
  padding-right: 15px;
}

.Statement-children {
  text-align: left;
  line-height: 1.4;
}
