.App {
  text-align: center;
  min-height: 100vh;
  width: 100vw;
  min-width: 300px;
  overflow: auto;
  font-family: 'Pangolin', sans-serif;
}

.App-logo {
  width: 100%;
  max-width: 800px;
  height: auto;
  margin: 2rem 0;
}

.App-container {
  padding: 0 10px;
  height: 100%;
  width: calc(100% - 20px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}

.App-text {
  font-size: calc(20px + 2vmin);
  font-family: 'Pangolin', sans-serif;
  margin-top: 60px;
}

.App-statement-header {
  margin: 1rem 0;
  font-size: 1.8rem;
}

.App-statement-wrapper {
  display: flex;
  flex-direction: column;
}

.App-contact-info-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin: 2rem 0;
  font-family: 'Pangolin', sans-serif;
}

.App-contact-info-lines-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.App-contact-info-name {
  margin: 0.4rem 0;
  font-size: 1.8rem;
}

.App-contact-info-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 1.5rem;
}

.App-question-logo {
  height: 110px;
  width: auto;
  margin-right: 15px;
}

@media (max-width: 675px) {
  .App-contact-info-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;
    margin: 2rem 0;
    font-family: 'Pangolin', sans-serif;
  }

  .App-contact-info-lines-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .App-contact-info-name {
    margin: 0.4rem 0;
    font-size: 1.8rem;
  }

  .App-contact-info-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1.5rem;
  }
}
